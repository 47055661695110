import React, { useEffect, useState } from "react";
import DIYBanner from "../assets/images/hero/DIY.jpg";
import Navbar from "./Navbar";
import "./DIY.css";
import "./HomeStyles.css";
import Footer from "./Footer";
import { DIYContent } from "./MenuData";


const DIY = (props) => {
  const imageMore = 3;
  // export const DIYImageContent = ({ DIYContent }) => {
  const [limit, setLimit] = useState(6);
  const handleMoreImage = () => {
    setLimit(limit + imageMore);
  };

  return (
    <>
      <Navbar />
      <div className="DIY-Container">
        <div className="DIY-Banner">
          <img className="DIY-img" src={DIYBanner} alt="DIY Banner" />
        </div>
        <div className="DIY-Content">
          <div className="container">
            <h1>Diton It Yourself</h1>
            <div className="diy_img_container">
              {/* {itmes.slice(0, visible).map((foto) => ( */}
              {DIYContent?.slice(0, limit)?.map((DIYItem, index) => {
                return (
                  <div className="DIY-Card" key={index}>
                    <img
                      className="diy_img"
                      alt="diyImg"
                      src={DIYItem.DIYImg}
                    />
                    <div className="DIY-Caption">
                      {/* <h1>{DIYItem.DIYTitle}</h1> */}
                      <p>{DIYItem.DIYCaption}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {limit < DIYContent?.length && (
              <button onClick={handleMoreImage}>More</button>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default DIY;
