import React from "react";
import "./SprayPaint.css";
import "./HomeStyles.css";
import { USPDataChrome } from "./MenuData";
import icon from "../assets/images/logo/iconUSP.png";
import ReactTextTransition, { presets } from "react-text-transition";
import { Ratio } from "react-bootstrap";
import CoverChrome from "../assets/images/cover/Cover-Web-Chrome.jpg";
import HTU1 from "../assets/images/HowToUse/Chrome/Chrome-1.jpg";  
import HTU2 from "../assets/images/HowToUse/Chrome/Chrome-2.jpg";
import HTU3 from "../assets/images/HowToUse/Chrome/Chrome-3.jpg";
const Product = (props) => {
  return (
    <div className="container">
      <div className="Product-details">
        <div className="Product-img">
          <img src={CoverChrome} />
        </div>
        <div className="Product-desc">
          <h2>DITON CHROME</h2>
          <h4>Cat Semprot yang memberikan efek seperti Chrome</h4>
          <p>
          Diton Chrome adalah cat semprot yang memberikan efek mirror menyerupai chrome. Diton Chrome dapat diaplikasikan ke berbagai media yang terbuat dari besi dan plastik. Dapatkan hasil yang berkilau seperti layaknya chrome asli.
          </p>
        </div>
        <div className="usp">
          {USPDataChrome.map((item, index) => {
            return (
              <div className="USPCard" key={index}>
                <div className="USPCardImg">
                  <img alt="logo" src={item.img} />
                </div>
                <div className="USPCardTitle">{item.title}</div>
                <div className="USPCardDesc">{item.dec}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="HowToUse">
        <h2>Cara Pemakaian</h2>
        <div className="HowToUse-img-container">
          <div className="HowToUse-img">
            <img alt="" src={HTU1} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU2} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU3} />
          </div>
          
        </div>
      </div>
      <div className="HowToApply">
        <p>Berikut adalah empat langkah dasar untuk pemakaian DITON Chrome:</p>
        <ul className="">
          <li>
            Bersihkan dan amplas permukaan media yang akan dicat, pastikan bersih, kering, bebas dari minyak, karat, kotoran, dll.
          </li>
          <li>
          Kocoklah kaleng selama 60 detik secara memutar hingga cat tercampur rata.
          </li>
          <li>
          Semprotkan 2-3 lapisan tipis merata dengan jarak semprot -+ 15cm. Beri jeda -+ 10 menit untuk setiap lapsiannya
          </li>
        </ul>
        <p>
        Penting untuk mengikuti petunjuk produsen untuk produk cat semprot DITON tertentu yang Anda gunakan, karena proses pengaplikasiannya mungkin berbeda-beda tergantung pada jenis cat dan permukaan yang Anda pengecatan.
        </p>
        <Ratio aspectRatio="16x9">
        <iframe
          className="VideoHowtoApply"
          src="https://www.youtube.com/embed/D6LGn4np5Ro"
        />
      </Ratio>
      </div>
    </div>
  );
};
export default Product;
