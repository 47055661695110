import "./HomeStyles.css";
import prouk from "../assets/images/produk/Hero Spray Paint.png";
import prouk2 from "../assets/images/produk/Hero Hi Temp.png";
import { USPDataHITemp, USPDataSprayPaint } from "./MenuData";
import { ProductList, DIYContent } from "./MenuData";
import Navbar from "./Navbar";
import Hero from "./Hero";
import VideoSlider from "./VideoSlider";
import Footer from "./Footer";
import { Link, ScrollRestoration } from "react-router-dom";
import { useEffect, useState } from "react";
import katalog from "../assets/images/color_chart/baner-katalog-standart2.jpg"

const Home = (props) => {
  const getArrayItems = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 576) {
      return DIYContent.slice(0, 2); // Show 2 items for small screens
    } else if (screenWidth < 992) {
      return DIYContent.slice(0, 4); // Show 4 items for medium screens
    } else {
      return DIYContent; // Show all items for large screens
    }
  };

  const arrayItems = getArrayItems();

  return (
    <>
      <Navbar />
      <Hero />
      <div className="home">
        <div className="container">
          {/* <hr /> */}
          <div className="first">
            <div className="desc_text">
              <h1>DITON SPRAY PAINT</h1>
              <h4>Cat Semprot Akrilik Serbaguna</h4>
              <p>
              Diton Spray Paint adalah cat semprot multiguna yang hadir lebih dari 100 warna yang menarik dan modern. Diton Spary Paint memiliki sifat multipurpose sehingga dapat diaplikasikan pada berbagai macam permukaan media. Diton Spray Paint multiguna tidak hanya untuk mewarnai tetapi juga untuk memperbaiki warna berbagai media seperti kendaraan bermotor dan benda lain yang terbuat dari besi, kayu, plastik, kaca, kain, permukaan dinding, dan lain-lain. 
              </p>
              <Link to="/Product/Standart" preventScrollReset={true}>
                <button className="btn_produk">VIEW MORE</button>
              </Link>
            </div>
            <div className="homeImg">
              <img alt="produk" src={prouk} />
            </div>
          </div>
          <div className="usp">
            {USPDataSprayPaint.map((item, index) => {
              return (
                <div className="USPCard" key={index}>
                  <div className="USPCardImg">
                    <img alt="logo" src={item.img} />
                  </div>
                  <div className="USPCardTitle">{item.title}</div>
                  <div className="USPCardDesc">{item.dec}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="hi-temp">
        <div className="container">
          <div className="first2">
            <div className="desc_text">
              <h1>DITON HI-TEMP</h1>
              <h4>Cat tahan panas hingga lebih dari 600℃.</h4>
              <p>
              Diton Hi-Temp adalah cat semprot yang diciptakan khusus memiliki ketahanan panas hingga 600℃. Dapat diaplikasikan pada media yang sering terkena panas, contohnya seperti knalpot kendaraan bermotor dan mobil, blok-blok mesin, kompor, tempat pembakaran, dsb.
              </p>
              <Link to="/Product/HITemp">
                <button className="btn_produk2">VIEW MORE</button>
              </Link>
            </div>
            <div className="homeImg">
              <img alt="produk" src={prouk2} />
            </div>
          </div>
          <div className="usp">
            {USPDataHITemp.map((item, index) => {
              return (
                <div className="USPCard" key={index}>
                  <div className="USPCardImg">
                    <img alt="logo" src={item.img} />
                  </div>
                  <div className="USPCardTitle">{item.title}</div>
                  <div className="USPCardDesc">{item.dec}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="diy">
        <div className="container">
          <h1>DITON It Yourself (DIY)</h1>
          <h3>DIY by Diton Spray Paint</h3>
          <div className="diy_img_container">
            {arrayItems.map((DIYItem, index) => {
              return (
                <div className="DIY-Card" key={index}>
                  <img className="diy_img" alt="diyImg" src={DIYItem.DIYImg} />
                  <div className="DIY-Caption">
                    {/* <h1>{DIYItem.DIYTitle}</h1> */}
                    <p>{DIYItem.DIYCaption}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <Link to="/DIY">
            <button>More</button>
          </Link>
        </div>
      </div>
      <div className="product_list">
        <div className="container">
          <h1>CAT SEMPROT DITON</h1>
          <div className="product_list_img">
            {ProductList.map((item,index ) => {
              return (
                <Link to={item.href} key={index}>
                  <div className="product" >
                    <img
                      className="product_img"
                      src={item.url}
                      alt={item.alt}
                    />
                    <h2>{item.name}</h2>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="katalog_digital">
        <div className="container">
          <h1>KATALOG DIGITAL</h1>
            <div className="katalog_digital_img">
              <a rel="noopener noreferrer" href="https://ditonspraypaint.com/digital-catalog/" target="_blank">
              <img className="imgkatalog" src={katalog} alt="KATALOG DIGITAL DITON SPRAY PAINT" />
              </a>
            </div>
        </div>

      </div>
      <VideoSlider />
      <Footer />
    </>
  );
};
export default Home;
