import React from "react";
import "./SprayPaint.css";
import "./HomeStyles.css";
import { USPDataHITemp } from "./MenuData";
import icon from "../assets/images/logo/iconUSP.png";
import ReactTextTransition, { presets } from "react-text-transition";
import { Ratio } from "react-bootstrap";
import CoverHiTemp from "../assets/images/cover/Cover-Web-Hi-Temp.jpg";
import HTU1 from "../assets/images/HowToUse/Hi-Temp/Hi-Temp 1.jpg";
import HTU2 from "../assets/images/HowToUse/Hi-Temp/Hi-Temp 2.jpg";
import HTU3 from "../assets/images/HowToUse/Hi-Temp/Hi-Temp 3.jpg";
const Product = () => {
  return (
    <div className="container">
      <div className="Product-details">
        <div className="Product-img">
          <img src={CoverHiTemp} />
        </div>
        <div className="Product-desc">
          <h2>DITON HI-TEMP</h2>
          <h4>Cat tahan panas hingga lebih dari 600℃.</h4>
          <p>
          Diton Hi-Temp adalah cat semprot yang diciptakan khusus memiliki ketahanan panas hingga 600℃. Dapat diaplikasikan pada media yang sering terkena panas, contohnya seperti knalpot kendaraan bermotor dan mobil, blok-blok mesin, kompor, tempat pembakaran, dsb.
          </p>
        </div>
        <div className="usp">
          {USPDataHITemp.map((item, index) => {
            return (
              <div className="USPCard" key={index}>
                <div className="USPCardImg">
                  <img alt="logo" src={item.img} />
                </div>
                <div className="USPCardTitle">{item.title}</div>
                <div className="USPCardDesc">{item.dec}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="HowToUse">
        <h2>Cara Pemakaian</h2>
        <div className="HowToUse-img-container">
          <div className="HowToUse-img">
            <img alt="" src={HTU1} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU2} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU3} />
          </div>
        </div>
      </div>
      <div className="HowToApply">
        <p>Berikut adalah empat langkah dasar untuk pemakaian DITON Hi-Temp:</p>
        <ul className="">
          <li>
            Bersihkan dan amplas permukaan media yang akan dicat, pastikan bersih, kering, bebas dari minyak, karat, kotoran, dll.
          </li>
          <li>
          Kocoklah kaleng selama 60 detik secara memutar hingga cat tercampur rata.
          </li>
          <li>
          Semprotkan 2-3 lapisan tipis merata dengan jarak semprot -+ 15cm. Beri jeda -+ 10 menit untuk setiap lapsiannya
          </li>
          {/* <li>
            Dry: Allow the paint to dry completely between coats, and wait for
            the final coat to dry thoroughly before using the painted object.
            The drying time will depend on the type of paint and the
            environmental conditions.
          </li> */}
        </ul>
        <p>
        Penting untuk mengikuti petunjuk produsen untuk produk cat semprot DITON tertentu yang Anda gunakan, karena proses pengaplikasiannya mungkin berbeda-beda tergantung pada jenis cat dan permukaan yang Anda pengecatan.
        </p>
        <Ratio aspectRatio="16x9">
        <iframe
          className="VideoHowtoApply"
          src="https://www.youtube.com/embed/HFPZCgyrclA"
        />
        </Ratio>
      </div>
    </div>
  );
};
export default Product;
