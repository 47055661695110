import React, { useEffect, useState } from "react";
import { VideoCarousel } from "./MenuData";
import "./HomeStyles.css";
import "../../node_modules/react-modal-video/css/modal-video.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Ratio } from "react-bootstrap";

const VideoSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    cssEase: "linear",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="slider col-md-12 ">
      <div className="container">
      <h2>YOUTUBE VIDEO DITON SPRAY PAINT</h2>

      <Slider {...settings}>
        {VideoCarousel.map((video, index) => {
          return (
            
            <div key={index}>
              {/* <img src={video.thumbnail} alt="Youtube Video" /> */}
              
              <ModalCreate
                variant="carouselVideo"
                imgSrc={video.thumbnail}
                Link={video.urlVideo}
              />
              
            </div>
          );
        })}
      </Slider>
      </div>
    </section>
    
  );
};

class ModalCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  render() {
    return (
      <>
        <img
          variant={this.props.variant}
          onClick={this.handleShow}
          src={this.props.imgSrc}
        />
        <Modal
          className="Custom-modal-content"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.state.handleClose}
          backdrop="static"
          size="xl"
        >
          <Modal.Body variant="Modalbody" bsPrefix="modal-body">
            <Ratio aspectRatio="16x9">
            <iframe
              width={"100% !important"}
              height={"500px !important"}
              // style={{
              //   width: 1110,
              //   aspectRatio: 2 / 1,
              // }}
              src={this.props.Link}
            />
            </Ratio>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default VideoSlider;
