import React from "react";
import "./ColorChart.css";
import Navbar from "./Navbar";
import { Colours } from "./MenuData";
import Footer from "./Footer";
import { Modal, Button, Ratio } from "react-bootstrap";
const ColorChart = () => {
  return (
    <>
      <Navbar />
      <div className="ColorChart">
        {Colours.map((Colours, index) => {
          return (
            <div key={Colours} className="ColorChart-Container">
              <div className="ColorChart-Title">
                <h1>{Colours.ColorsName}</h1>
              </div>
              <div className="Color-item">
                {Colours.Color.map((Color, index) => {
                  return (
                    <div className="Color-card">
                      <ModalCreate
                      variant='ColorChart'
                      imgSrc={Color.ColorImg}
                      Link={Color.UrlVideo}/>
                      <p className="Color-code">{Color.ColorCode}</p>
                      <p className="Color-name">{Color.ColorName}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <Footer />
    </>
  );
};

class ModalCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  render() {
    return (
      <>
        <img 
          variant={this.props.variant}
          onClick={this.handleShow}
          src={this.props.imgSrc}
        />
        
        <Modal 
          className="Custom-modal-content"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.state.handleClose}
          backdrop="static"
          size="xl"
        >
          <Modal.Body variant="Modalbody" bsPrefix="modal-body">
            <Ratio aspectRatio="16x9">
            <iframe
              width={"100% !important"}
              height={"500px !important"}
              // style={{
              //   width: 1110,
              //   aspectRatio: 2 / 1,
              // }}
              src={this.props.Link}
            />
            </Ratio>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default ColorChart;
