import React from "react";
import "./SprayPaint.css";
import "./HomeStyles.css";
import TextTransition, { presets } from "react-text-transition";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Routes, Route, NavLink, Switch, Outlet } from "react-router-dom";

const TEXTS = ["MATTER", "ITEMS", "PRODUCTS", "THINKS"];
const Product = () => {
  const [TextsIndex, setTextsIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setTextsIndex((TextsIndex) => TextsIndex + 1),

      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <Navbar />

      <div className="Product-container">
        {/* <div className="Product-header">
          <section>
            DITON SPRAY
            <div className="header-titile-product">
              <section>
                LET'S PAINT YOUR{" "}
                <TextTransition
                  springConfig={presets.gentle}
                  style={{ margin: "0 4px" }}
                  inline
                >
                  {TEXTS[TextsIndex % TEXTS.length]}
                </TextTransition>
              </section>
            </div>
          </section>
        </div> */}
        <div className="Product-header">
          <section>
            DITON SPRAY
            <div className="header-titile-product">
              <section>
                LET'S PAINT YOUR
                <section class="animation">
                  <div class="first"><div>MATTER</div></div>
                  <div class="second"><div>ITEMS</div></div>
                  <div class="third"><div>PRODUCTS</div></div>
                  <div class=""><div>THINKS</div></div>
                </section>
              </section>
            </div>
          </section>
        </div>
        <div className="Product-details">
          <div className="Navigator-container">
            <div className="Product-Navigator">
              
                  <NavLink
                    exact
                    className={({ isActive, isPending }) =>
                      isPending ? "link-active" : isActive ? "link-active" : ""
                    }
                    style={{ color: "#045FD1" }}
                    to="Standart"
                  >
                    SPRAY PAINT
                  </NavLink>
                
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "link-active" : isActive ? "link-active" : ""
                    }
                    style={{ color: "#045FD1" }}
                    to="HITemp"
                  >
                    HI-TEMP
                  </NavLink>
                
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "link-active" : isActive ? "link-active" : ""
                    }
                    style={{ color: "#045FD1" }}
                    to="Chrome"
                  >
                    CHROME
                  </NavLink>
                
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "link-active" : isActive ? "link-active" : ""
                    }
                    style={{ color: "#045FD1" }}
                    to="Flintkote"
                  >
                    FLINTKOTE
                  </NavLink>
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "link-active" : isActive ? "link-active" : ""
                    }
                    style={{ color: "#045FD1" }}
                    to="diton-gold-series"
                  >
                    GOLD SERIES
                  </NavLink>
                
                <div className="bar"></div>
              
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
export default Product;
