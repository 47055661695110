import "./HeroStyles.css";
import Heroimg from "../assets/images/hero/Header Spray Paint.jpg";
function Hero() {
  return (
    <>
      <div className="hero">
        <img className="heroImg" src={Heroimg} alt="HeroImg" />
      </div>
    </>
  );
}

export default Hero;
